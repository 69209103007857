.VideoPlayer_player {
  height: 560px;
  width: 100%;
  max-width: 1190px;
  margin: 0 auto;
  position: relative;
  padding-top: 50px;
}

@media (max-width: 1024px) {
  .VideoPlayer_player {
    height: 340px;
    background-position: center 54px;
    padding-top: 0;
  }
}
